const React = require("react")
const Layout = require("./src/components/layout")
// Logs when the client route changes
exports.onRouteUpdate = ({ location, prevLocation }) => {
    const data = window.dataLayer

    if (typeof data === `object`) {
        const eventName = `gatsbyRouteChange`
        data.push({ event: eventName })
    }
}
