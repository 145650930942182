import React, { useRef, useState } from "react";
import SectionTitle from "../components/section-title";
import { graphql, useStaticQuery } from "gatsby";

const Testimonials = ({ small = false }) => {
  const data = useStaticQuery(query);
  const [posX, setPosX] = useState(0)
  let scrollContainer = useRef()

  const reviewsCount = data.allGoogleReview.edges.length

  const scrollReviews = (e) => {
    const scrollWidth = scrollContainer.current.scrollWidth
    const reviewWidth = scrollWidth / reviewsCount;
    let left = 0;
    if (+e.target.value === -1) {
      const isAtEnd = Math.abs(scrollWidth - (scrollContainer.current.scrollLeft + scrollContainer.current.clientWidth)) < 20
      if (isAtEnd)
        return

      const newPosX = Math.min(posX + 1, reviewsCount - 1)
      left = reviewWidth * newPosX
      setPosX(newPosX)
      scrollContainer.current.scrollTo({
        left,
        behavior: "smooth",
      })
    }
    else if (+e.target.value === 1) {
      const newPosX = Math.max(posX - 1, 0)
      setPosX(newPosX)
      left = reviewWidth * newPosX
      scrollContainer.current.scrollTo({
        left,
        behavior: "smooth",
      })
    }
  }

  const { node: { global_rating, user_ratings_total } } = data.allGoogleReview.edges[0]

  return (
    <div>

      <div className={`bg-white relative left-1/2 transform -translate-x-1/2 ${small ? 'py-2' : 'py-2 '}  overflow-hidden`}>
        <div ref={scrollContainer} className="flex space-x-8 container overflow-x-hidden pb-4 px-8">
          {data && data.allGoogleReview && data.allGoogleReview.edges.map(({ node: review }) => (
            <div key={review.id} className={`bg-gray-100 p-4 flex-shrink-0 flex flex-col items-center space-y-0 shadow rounded-md ${small ? 'w-36' : 'w-72 '}`}>
              <div className="rounded-full w-14 h-14 p-2 bg-gray-200"><img alt={review.author_name} className="text-xs" src={review.profile_photo_url} /></div>
              <div className="whitespace-nowrap text-ellipsis">{review.author_name}</div>
              {!small && <div className="text-sm">{review.relative_time_description}</div>}
              <div>
                <div className={small ? `rate rate-small` : `rate`} rate={review.rating}>
                  {[5, 4, 3, 2, 1].map(rate => ([
                    <input key={0} type="checkbox" id={'star' + rate} defaultChecked={+review.rating >= +rate} name="rate" value={rate} />,
                    <label key={1} htmlFor={'star' + rate} title="text">{rate} stars</label>
                  ]
                  ))}
                </div>
              </div>
              {!small && <div className="text-center text-sm">{review.text}</div>}
            </div>
          ))}
        </div>
        <div className="flex justify-center space-x-4">
          <div className="bg-gray-200 text-3xl font-bold rounded-full px-4 pb-1 "><button type="button" onClick={scrollReviews} value={1} className="focus:outline-none  text-white ">&lsaquo;</button></div>
          <div className="bg-gray-200 text-3xl font-bold rounded-full px-4 pb-1 "><button type="button" onClick={scrollReviews} value={-1} className="focus:outline-none  text-white ">&rsaquo;</button></div>
        </div>
      </div>

      <div className="flex justify-center items-center">
        <span className="font-semibold text-xl">{global_rating}</span>
        <div className="rate" rate={global_rating}>
          {[5, 4, 3, 2, 1].map(rate => ([
            <input key={0} type="checkbox" id={'star' + rate} defaultChecked={+global_rating >= +rate} name="rate" value={rate} />,
            <label key={1} title="text">{rate} stars</label>
          ]
          ))}
        </div>
        <span className="font-semibold text-xl">{user_ratings_total} avis Google</span>
      </div>
    </div>
  );
};

const query = graphql`
  query{
    allGoogleReview {
      edges {
        node {
          id
          rating
          position
          author_name
          global_rating
          user_ratings_total
          author_url
          profile_photo_url
          relative_time_description
          text
          time
        }
      }
    }
  }
`;



export default Testimonials;
