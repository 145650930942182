import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";

const Hero = ({ title, bgImage, altBgImage }) => {
  const data = useStaticQuery(query);

  return (
    <>
      {bgImage && (
        <div className="container p-0 ">
          {title && (<h1 className="text-blue">{title}</h1>)}
          <div className="rounded-4xl overflow-hidden mb-12">
            <div className="h-28rem relative">
              <div>
                <GatsbyImage alt={altBgImage || ''} image={getImage(bgImage)} className="object-cover"></GatsbyImage>
              </div>
            </div>
          </div>
        </div>
      )}
      {
        !bgImage && (
          <div className="container p-0 rounded-4xl overflow-hidden mb-12">
            <div className="h-28rem relative">
              <GatsbyImage className='object-cover w-full h-full absolute left-0 top-0 opacity-50 lg:opacity-100' alt='hero' image={getImage(data.backgrounds.nodes[0].childImageSharp)} ></GatsbyImage>
              <div className="absolute mt-16 w-full md:w-7/12 lg:w-5/12 pl-16 top-0">
                <h1 className="text-4xl text-blue font-light normal-case leading-snug mb-14">
                  Tote bag personnalisé ou sac en coton personnalisé ? C’est notre spécialité
                </h1>
                <Link
                  className="btn btn-blue btn-rounded uppercase py-3 rounded-3xl"
                  to="/categorie-produit/tote-bags-personnalises"
                >
                  Découvrir nos sacs en tissu
                </Link>
              </div>
              {/* <BackgroundSlider
                query={ }
              >
                {[1, 2, 3].map((e, i) => (
                  <div key={e} className="absolute mt-16 w-full sm:w-7/12 pl-16">
                    {i === 0 && <h1 className="text-4xl text-blue font-light normal-case leading-snug mb-14">
                      Tote bag personnalisé ou sac en coton personnalisé ? C’est notre spécialité
                    </h1>}
                    <Link
                      className="btn btn-blue btn-rounded uppercase py-3 rounded-3xl"
                      to="/categorie-produit/tote-bags-personnalises"
                    >
                      Découvrir nos sacs en tissu
                    </Link>
                  </div>
                ))}
              </BackgroundSlider> */}
            </div>
          </div>
        )
      }
    </>)
};

const query = graphql`
      query {
        backgrounds: allFile(
          filter: {sourceInstanceName: {eq: "backgrounds"}, name: {eq: "new_bandeau"}}
        ) {
          nodes {
            relativePath
            childImageSharp  {
              gatsbyImageData(quality: 90, width: 1200)
            }
          }
        }
      }
    `

export default Hero;
