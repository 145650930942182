import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { categoryParams } from "../../../node-category";

const CategoryTree = ({ parent = null }) => {
  const data = useStaticQuery(query);
  return (
    <ul className="product-categories">
      {
        data.allStrapiCategory.nodes
          .filter(
            (cat) =>
              (cat.products.length > 0 || cat.strapiChildren.length > 0) &&
              ((cat.strapiParent || null) && cat.strapiParent.id) == parent
          )
          .map((cat, i) => (
            <li key={parent + i}>
              <Link to={categoryParams.urlPrefix + cat.slug}>{cat.name}</Link>
              {cat.strapiChildren && cat.strapiChildren.length > 0 && (
                <div className="pl-4">
                  <CategoryTree parent={cat.strapiId}></CategoryTree>
                </div>
              )}
            </li>
          ))
      }
    </ul>
  );
};

const query = graphql`
  query {
    allStrapiCategory(sort: {position: ASC})  {
      nodes {
        slug
        id
        position
        name
        strapiId
        strapiParent {
          id
        }
        strapiChildren {
          id
        }
        products {
          id
        }
      }
    }
  }
`;

export default CategoryTree;
