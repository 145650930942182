import React from "react";
import { Link } from "gatsby";
import { productUrl } from "../../../node-product";
import { categoryParams } from "../../../node-category";
import SeparatorComponent from "../separator/separator";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ProductList = ({ products, cols = 3 }) => {
  return (
    <>
      <div className="flex flex-wrap ">
        {products.map((product, index) => (
          <div
            key={index}
            className={`w-6/12 ${cols === 3 ? "md:w-4/12" : "md:w-3/12"} p-1`}
          >
            <div className="h-full p-1 flex flex-col justify-between border-gray-200 border-1">
              <div className="h-72">
                <Link to={productUrl(product)}>
                  {product.image && (
                    <GatsbyImage
                      className="w-full h-full"
                      alt={product.title}
                      image={getImage(product.image.localFile)}
                      proportions={[238, 288]}
                    />
                  )}
                </Link>
              </div>
              <div className="flex-1 flex flex-col justify-around">
                {/* {product.categories.length > 0 && (
                  <Link
                    rel="nofollow"
                    to={categoryParams.urlPrefix + product.categories[0].slug}
                    className="block text-center text-gray-600 text-xs my-3 font-bold uppercase underline"
                  >
                    {product.categories[0].name}
                  </Link>
                )}
                <SeparatorComponent center={true}></SeparatorComponent> */}
                <Link
                  to={productUrl(product)}
                  className="text-center rounded bg-blue text-white uppercase py-1 px-2 text-sm flex flex-col items-center"
                >
                  {formatProductTitle(product.title)}
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

function formatProductTitle(title) {
  const matches = title.match(/^([^(]+)(\(.+\))$/);
  if (matches) {
    return (
      <>
        <span>{matches[1]}</span>
        <span>{matches[2]}</span>
      </>
    );
  }
  return <span>{title}</span>;
}

export default ProductList;
