import React from "react";

const SectionTitle = ({ children, color, mb = 12 }) => {
  return (
    <div className="flex">
      <div
        className={`h-4 border-b-1 flex-grow mb-${mb} ${color ? "border-" + color : "border-blue"
          }`}
      ></div>
      <h2
        className={`uppercase text-3xl text-center px-6 mb-0 ${color ? "text-" + color : "text-blue"
          }`}
      >
        {children}
      </h2>
      <div
        className={`h-4 border-b-1 flex-grow ${color ? "border-" + color : "border-blue"
          }`}
      ></div>
    </div>
  );
};

export default SectionTitle;
