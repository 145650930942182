import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const IconsLineCat = () => {
  const data = useStaticQuery(query);

  const icons = [
    {
      src: data.iconline1,
      text: "DEVIS SOUS 24H",
      alt: "DEVIS SOUS 24H",
    },
    {
      src: data.iconline2,
      text: "QUALITÉ GARANTIE",
      alt: "QUALITÉ GARANTIE",
    },
    {
      src: data.iconline3,
      text: "POSSIBILITÉ D'ENVOI D'ÉCHANTILLON",
      alt: "POSSIBILITÉ D'ENVOI D'ÉCHANTILLON",
    },
    {
      src: data.iconline4,
      text: "MAQUETTE VIRTUELLE",
      alt: "MAQUETTE VIRTUELLE",
    },
    {
      src: data.iconline5,
      text: "LIVRAISON EN 7 JOURS OUVRÉS",
      alt: "LIVRAISON EN 7 JOURS OUVRÉS",
    },
    {
      src: data.iconline6,
      text: "LIVRAISON OFFERTE",
      alt: "LIVRAISON OFFERTE",
    },
    {
      src: data.iconline7,
      text: "IMPRESSION FRANÇAISE",
      alt: "IMPRESSION FRANÇAISE",
    },
  ];

  return (
    <ul className="hidden md:grid md:grid-cols-7 list-none justify-between  ">
      {icons.map((icon, i) => (
        <li key={i} className="">
          <div className="text-center">
            {icon.src && (
              <GatsbyImage
                className="w-16 inline-block"
                image={getImage(icon.src)}
                alt={icon.alt}
              />)}
          </div>
          <h6 className="mb-0 text-sm font-semi-bold text-gray-700 text-center">{icon.text}</h6>
        </li>
      ))}
    </ul>
  );
};

const query = graphql`
  query {
    iconline1: file( relativePath: { eq: "icones15.png" } ) {
      childImageSharp {
        gatsbyImageData(width: 120, height: 120) 
      }
    }
    iconline2: file( relativePath: { eq: "icones16.png" } ) {
      childImageSharp {
        gatsbyImageData(width: 120, height: 120) 
      }
    }
    iconline3: file( relativePath: { eq: "icones17.png" } ) {
      childImageSharp {
        gatsbyImageData(width: 120, height: 120) 
      }
    }
    iconline4: file( relativePath: { eq: "icones18.png" } ) {
      childImageSharp {
        gatsbyImageData(width: 120, height: 120) 
      }
    }
    iconline5: file( relativePath: { eq: "icones14.png" } ) {
      childImageSharp {
        gatsbyImageData(width: 120, height: 120) 
      }
    }
    iconline6: file( relativePath: { eq: "icones13.png" } ) {
      childImageSharp {
        gatsbyImageData(width: 120, height: 120) 
      }
    }
    iconline7: file( relativePath: { eq: "icones19.png" } ) {
      childImageSharp {
        gatsbyImageData(width: 120, height: 120) 
      }
    }
  }
`;

export default IconsLineCat;
