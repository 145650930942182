require("dotenv").config({
  path: `.env`,
});

module.exports = {
  flags: {},
  trailingSlash: 'never',
  siteMetadata: {
    // If you didn't use the resolveSiteUrl option this needs to be set
    siteUrl: `https://latelierdutotebag.com`,
  },
  plugins: [
    {
      resolve: `google-reviews`,
      options: {
        placeId: `ChIJkwExxtx65kcRyT7RA6p5VcQ`,
        apiKey: "AIzaSyBChhCwSbBPF7LfihMYicoj81AnywFzkPw",
      },
    },
    {
      resolve: `gatsby-plugin-sitemap`,
      options: {
        excludes: [
          '/tag/tote-bag',
          '/categorie-produit',
          '/remerciements',
          '/le-blog-la-fabrique-du-coton-imprimeur-de-textile-personnalise/4',
          '/le-blog-la-fabrique-du-coton-imprimeur-de-textile-personnalise/5',
          '/le-blog-la-fabrique-du-coton-imprimeur-de-textile-personnalise/2',
          '/le-blog-la-fabrique-du-coton-imprimeur-de-textile-personnalise/3',
          '/le-blog-la-fabrique-du-coton-imprimeur-de-textile-personnalise/8',
          '/le-blog-la-fabrique-du-coton-imprimeur-de-textile-personnalise/9',
          '/le-blog-la-fabrique-du-coton-imprimeur-de-textile-personnalise/6',
          '/le-blog-la-fabrique-du-coton-imprimeur-de-textile-personnalise/7',
          '/sacs-en-tissu-personnalises',
          // '/sacs-en-coton-personnalises',
          // '/sacs-sport-personnalises',
          // '/tote-bags-personnalises-professionnels',
          // '/tote-bags-personnalises-en-coton-bio',
          // '/cookie-preferences',
          // '/tote-bags-vierges-a-personnaliser',
          // '/sacs-plage-personnalises',
          // '/tote-bags-blancs-a-personnaliser',
          // '/les-tote-bags-sur-mesure',
        ],
        // serialize: ({ path, modifiedGmt }) => {
        //   return {
        //     url: path,
        //     lastmod: modifiedGmt,
        //   }
        // },
      }
    },
    "gatsby-plugin-react-helmet",
    {
      resolve: 'gatsby-plugin-robots-txt',
      options: {
        // host: 'https://latelierdutotebag.com',
        sitemap: 'https://latelierdutotebag.com/sitemap-index.xml',
        policy: [
          {
            userAgent: '*',
            // allow: '/',
            disallow: ['/remerciements', '/*confirmation*', '/tag/*', '/devis-envoye/', '/merci-pour-votre-participation/']
          }]
      }
    },

    // {
    //   resolve: `gatsby-plugin-gdpr-cookies`,
    //   options: {
    //     // googleAnalytics: {
    //     //   trackingId: 'YOUR_GOOGLE_ANALYTICS_TRACKING_ID', // leave empty if you want to disable the tracker
    //     //   cookieName: 'gatsby-gdpr-google-analytics', // default
    //     //   anonymize: true, // default
    //     //   allowAdFeatures: true // default
    //     // },
    //     googleTagManager: {
    //       trackingId: 'GTM-NNS4CK', // leave empty if you want to disable the tracker
    //       cookieName: 'gatsby-gdpr-google-tagmanager', // default
    //       dataLayerName: 'dataLayer', // default
    //     },
    //     // Defines the environments where the tracking should be available  - default is ["production"]
    //     environments: ['production', 'development']
    //   },
    // },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `logos`,
        path: `${__dirname}/src/assets/logos`,
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `realisations`,
        path: `${__dirname}/src/assets/realisations`,
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `uploads`,
        path: `${__dirname}/src/assets/uploads`,
      },
    },
    // {
    //   resolve: "gatsby-plugin-google-tagmanager",
    //   options: {
    //     id: "GTM-NNS4CK",
    //     includeInDevelopment: false,
    //     // datalayer to be set before GTM is loaded
    //     // should be an object or a function that is executed in the browser
    //     //
    //     // Defaults to null
    //     // defaultDataLayer: { platform: "gatsby" },

    //     // Specify optional GTM environment details.
    //     // Defaults to gatsby-route-change
    //     routeChangeEventName: "ROUTE_CHANGE_EVENT",
    //     // Defaults to false
    //     // enableWebVitalsTracking: true,
    //   },
    // },
    {
      resolve: 'gatsby-plugin-local-search',
      options: {
        name: 'products',
        engine: 'flexsearch',
        // engineOptions: 'speed',
        // GraphQL query used to fetch all data for the search index. This is
        // required.
        query: `
          {
            allStrapiProduct {
              nodes {
                id
                title
                image {
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData(width: 230, height: 280)
                    }
                  }
                }
                categories {
                  id
                  name
                  slug
                }
                slug
              }
            }
          }
        `,
        // Field used as the reference value for each document.
        // Default: 'id'.
        ref: 'slug',

        // List of keys to index. The values of the keys are taken from the
        // normalizer function below.
        // Default: all fields
        index: ['title'],

        // List of keys to store and make available in your UI. The values of
        // the keys are taken from the normalizer function below.
        // Default: all fields
        store: ['slug', 'title', 'categories', 'image'],

        // Function used to map the result from the GraphQL query. This should
        // return an array of items to index in the form of flat objects
        // containing properties to index. The objects must contain the `ref`
        // field above (default: 'id'). This is required.
        normalizer: ({ data }) =>
          data.allStrapiProduct.nodes.map((node) => ({
            id: node.id,
            slug: node.slug,
            title: node.title,
            image: node.image,
            categories: node.categories,
          })),
      },
    },
    {
      resolve: "gatsby-source-strapi",
      options: {
        apiURL: "http://data.latelierdutotebag.com:1337",
        collectionTypes: [
          "article",
          "category",
          "writer",
          "product",
          "tag",
          "page",
        ],
        singleTypes: [`homepage`, `global`],
        queryLimit: 5000,
      },
    },
    `gatsby-plugin-image`,
    "gatsby-transformer-sharp",
    {
      resolve: "gatsby-plugin-sharp",
      options: {
        defaults: {
          placeholder: 'blurred',
          formats: [`auto`, `webp`],
          quality: 70,
          breakpoints: [750, 1080, 1366, 1920],
          backgroundColor: `transparent`,
          tracedSVGOptions: {},
          blurredOptions: {},
          jpgOptions: {},
          pngOptions: {},
          webpOptions: {},
          avifOptions: {},
        }
      }
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: "gatsby-starter-default",
        short_name: "starter",
        start_url: "/",
        background_color: "#663399",
        theme_color: "#663399",
        display: "minimal-ui",
        icon: 'src/assets/logos/Logo-latelierdutotebag.png',
      },
    },
    // "gatsby-plugin-offline",
    "gatsby-plugin-remove-serviceworker",
    "gatsby-plugin-postcss",
    {
      resolve: "gatsby-plugin-react-svg",
      options: {
        rule: {
          include: /icons/,
        },
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `backgrounds`,
        path: `${__dirname}/src/assets/bg`, // wherever background images are stored
      },
    },
    {
      resolve: "gatsby-plugin-netlify-headers",
      options: {
        // headers: {},                                  // option to add more headers. `Link` headers are transformed by the below criteria
        // allPageHeaders: [],                           // option to add headers for all pages. `Link` headers are transformed by the below criteria
        // mergeSecurityHeaders: true,                   // boolean to turn off the default security headers
        // mergeLinkHeaders: false,                      // boolean to turn off the default gatsby js headers (disabled by default, until gzip is fixed for server push)
        // mergeCachingHeaders: true,                    // boolean to turn off the default caching headers
        // transformHeaders: (headers, path) => headers, // optional transform for manipulating headers under each path (e.g.sorting), etc.
      },
    },
  ],
};
